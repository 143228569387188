import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { AnimatePresence } from "framer-motion";
import PageTransition from "../common/PageTransition";

export default function Layout({ children }) {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-pi-white">
      <Navbar />
      <AnimatePresence mode="wait" initial={false}>
        <main key={location.pathname} className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
          <PageTransition>{children}</PageTransition>
        </main>
      </AnimatePresence>
      <Footer />
    </div>
  );
}
