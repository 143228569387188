import { useState, useEffect } from 'react';
import games2024 from '../../data/games2024.json';
import power5teams from '../../data/power5teams.json';
import conferenceRankings from '../../data/conferenceRankings.json';

const defaultWeights = {
    winLoss: 0.65,
    strengthOfSchedule: 0.3,
    conferenceRanking: 0.05,
};

export default function CFBRankings() {
    const [rankings, setRankings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weights, setWeights] = useState(defaultWeights);

    useEffect(() => {
        calculateRankings();
    }, [weights]);

    const calculateRankings = () => {
        setLoading(true);
        setError(null);

        try {
            // Convert power5teams array to Set of school names
            const power5TeamSet = new Set(power5teams.map(team => team.school));

            const teamStats = {};

            // Initialize stats for all teams that appear in games
            games2024.forEach(game => {
                if (!game.home_team || !game.away_team ||
                    typeof game.home_points !== 'number' ||
                    typeof game.away_points !== 'number') {
                    return;
                }

                const { home_team, away_team } = game;

                if (!teamStats[home_team]) {
                    teamStats[home_team] = { wins: 0, losses: 0, opponents: [] };
                }
                if (!teamStats[away_team]) {
                    teamStats[away_team] = { wins: 0, losses: 0, opponents: [] };
                }
            });

            // Calculate wins and losses for all games
            games2024.forEach(game => {
                if (!game.home_team || !game.away_team ||
                    typeof game.home_points !== 'number' ||
                    typeof game.away_points !== 'number') {
                    return;
                }

                const { home_team, away_team, home_points, away_points } = game;

                // Record all matchups
                teamStats[home_team].opponents.push(away_team);
                teamStats[away_team].opponents.push(home_team);

                if (home_points > away_points) {
                    teamStats[home_team].wins++;
                    teamStats[away_team].losses++;
                } else if (away_points > home_points) {
                    teamStats[away_team].wins++;
                    teamStats[home_team].losses++;
                }
            });

            // Calculate rankings with weighted factors
            const newRankings = Object.entries(teamStats)
                .filter(([team]) => power5TeamSet.has(team))
                .map(([team, stats]) => {
                    // Calculate strength of schedule
                    const opponentWins = stats.opponents.reduce((total, opponent) => {
                        return total + (teamStats[opponent]?.wins || 0);
                    }, 0);
                    const strengthOfSchedule = stats.opponents.length > 0
                        ? opponentWins / stats.opponents.length
                        : 0;

                    // Calculate win percentage
                    const totalGames = stats.wins + stats.losses;
                    const winPercentage = totalGames > 0 ? stats.wins / totalGames : 0;

                    // Get team info and conference ranking
                    const teamInfo = power5teams.find(t => t.school === team);
                    const confRanking = conferenceRankings.find(c => c.conference === teamInfo?.conference);

                    // Calculate weighted score
                    const score =
                        (winPercentage * weights.winLoss) +
                        (strengthOfSchedule / 12 * weights.strengthOfSchedule) + // Normalize SoS by max possible wins
                        ((5 - (confRanking?.rank || 5)) / 4 * weights.conferenceRanking); // Normalize conf rank to 0-1

                    return {
                        team,
                        wins: stats.wins,
                        losses: stats.losses,
                        strengthOfSchedule,
                        score,
                        conference: teamInfo?.conference,
                        conferenceRank: confRanking?.rank || 5,
                        conferenceRecord: confRanking ? `${confRanking.wins}-${confRanking.losses}` : 'N/A'
                    };
                });

            // Sort by weighted score
            newRankings.sort((a, b) => b.score - a.score);

            // Set only top 25
            setRankings(newRankings.slice(0, 25));
        } catch (err) {
            setError('Failed to calculate rankings.');
            console.error('Error calculating rankings:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleWeightChange = (type, value) => {
        const newWeights = { ...weights, [type]: parseFloat(value) };
        // Normalize weights to sum to 1
        const sum = Object.values(newWeights).reduce((a, b) => a + b, 0);
        const normalizedWeights = Object.fromEntries(
            Object.entries(newWeights).map(([key, val]) => [key, val / sum])
        );
        setWeights(normalizedWeights);
    };

    return (
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
            <div className="space-y-8">
                <div className="text-center">
                    <h1 className="text-4xl font-bold">College Football Rankings</h1>
                    <div className="mx-auto mt-6 max-w-3xl text-lg text-gray-600">
                        <p className="mb-4">
                            The college football playoff selection process has always been a mix of art and science,
                            often leaving fans and teams frustrated with its lack of transparency. What if we could
                            make it more objective? This project explores a systematic approach to ranking teams
                            based on three key factors: win-loss record, strength of schedule, and conference performance.
                        </p>
                        <p>
                            By adjusting the importance of each factor, we can see how different emphases create
                            different outcomes. While this may not capture every nuance of team evaluation, it offers
                            a starting point for a more transparent, predictable system. The goal isn't to replace
                            human judgment entirely, but to provide a clear framework that could help everyone
                            understand exactly what it takes to make the playoff.
                        </p>
                    </div>
                </div>

                {/* Interactive Section */}
                <div className="rounded-lg border border-gray-200 bg-white p-8">
                    <div className="grid gap-8 lg:grid-cols-2">
                        {/* Sliders */}
                        <div className="space-y-6">
                            <h2 className="text-2xl font-semibold">Try Out Your Own Ranking System</h2>
                            <div>
                                <label className="mb-2 block text-sm font-medium">
                                    Win-Loss Ratio ({(weights.winLoss * 100).toFixed(0)}%)
                                </label>
                                <input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.05"
                                    value={weights.winLoss}
                                    onChange={(e) => handleWeightChange('winLoss', e.target.value)}
                                    className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                                />
                            </div>
                            <div>
                                <label className="mb-2 block text-sm font-medium">
                                    Strength of Schedule ({(weights.strengthOfSchedule * 100).toFixed(0)}%)
                                </label>
                                <input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.05"
                                    value={weights.strengthOfSchedule}
                                    onChange={(e) => handleWeightChange('strengthOfSchedule', e.target.value)}
                                    className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                                />
                            </div>
                            <div>
                                <label className="mb-2 block text-sm font-medium">
                                    Conference Strength ({(weights.conferenceRanking * 100).toFixed(0)}%)
                                </label>
                                <input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.05"
                                    value={weights.conferenceRanking}
                                    onChange={(e) => handleWeightChange('conferenceRanking', e.target.value)}
                                    className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                                />
                            </div>
                        </div>

                        {/* Conference Rankings Table */}
                        <div className="overflow-hidden rounded-lg border border-gray-200">
                            <div className="px-6 py-4">
                                <h3 className="text-lg font-semibold">Current Conference Rankings</h3>
                                <p className="text-sm text-gray-600">Based on inter-conference games</p>
                            </div>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Rank</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Conference</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Record</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Win %</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {conferenceRankings.map((conf) => (
                                        <tr key={conf.conference}>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">{conf.rank}</td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">{conf.conference}</td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{conf.wins}-{conf.losses}</td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                                {(conf.winPercentage * 100).toFixed(1)}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Team Rankings Table */}
                <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
                    {loading ? (
                        <div className="flex h-64 items-center justify-center">
                            <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-900 border-t-transparent" />
                        </div>
                    ) : error ? (
                        <div className="p-4 text-center text-red-600">{error}</div>
                    ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Rank
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Team
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Conference
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Record
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        SoS
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Conf. Rank
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {rankings.map((team, index) => (
                                    <tr
                                        key={team.team}
                                        className={
                                            index < 12
                                                ? index < 4
                                                    ? 'bg-green-50'  // Top 4 (slightly darker)
                                                    : 'bg-green-50/50'  // 5-12 (lighter)
                                                : ''
                                        }
                                    >
                                        <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                            {index + 1}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                            {team.team}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                            {team.conference}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                            {team.wins}-{team.losses}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                            {team.strengthOfSchedule.toFixed(2)}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                            #{team.conferenceRank} ({team.conferenceRecord})
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
} 