import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import PageTransition from './components/common/PageTransition';
import CFBRankings from './pages/Projects/CFBRankings';
import SuperBallBreaker from './pages/Projects/SuperBallBreaker';
import SlimeMold from './pages/Projects/SlimeMold';
import SEO from './components/common/SEO';
import BlogPost from './components/BlogPost';

// Lazy load route components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const Projects = lazy(() => import('./pages/Projects'));
const Blog = lazy(() => import('./pages/Blog'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Simple loading component
const Loading = () => (
  <div className="flex h-screen items-center justify-center">
    <div className="h-8 w-8 animate-pulse rounded-full bg-gray-200" />
  </div>
);

export default function App() {
  return (
    <BrowserRouter>
      <SEO />
      <Layout>
        <Suspense fallback={<Loading />}>
          <PageTransition>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/cfb-rankings" element={<CFBRankings />} />
              <Route path="/projects/super-ball-breaker" element={<SuperBallBreaker />} />
              <Route path="/projects/slime-mold" element={<SlimeMold />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageTransition>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
}
