import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const defaultMeta = {
    title: "Polity Interfaces | AI Development & Web Solutions",
    description:
        "Expert AI development and web solutions by former SpaceX engineer. We specialize in machine learning, web applications, and technical consulting to simplify complex challenges.",
    image: "https://polityinterfaces.com/og-image.png",
    imageWidth: "1200",
    imageHeight: "630",
    type: "website",
};

const pageMeta = {
    "/": {
        title: "Polity Interfaces | AI Development & Web Solutions",
        description:
            "Expert AI development and web solutions by former SpaceX engineer. From slick apps to smart AI, we simplify complexity.",
        type: "website",
    },
    "/about": {
        title: "About Polity Interfaces | Former SpaceX Engineer",
        description:
            "Led by former SpaceX engineer Trey Reynolds, Polity Interfaces brings enterprise expertise to AI development and web solutions.",
        type: "profile",
    },
    "/services": {
        title: "Services | AI Development, Web Solutions & Technical Consulting",
        description:
            "Comprehensive tech services including AI development, web applications, technical consulting, and custom developer tools.",
        type: "website",
    },
    "/projects": {
        title: "Projects | AI and Web Development Portfolio",
        description:
            "Explore our portfolio of AI solutions, web applications, and developer tools that solve real-world challenges.",
        type: "website",
    },
    "/contact": {
        title: "Contact Polity Interfaces | Let's Build Something Great",
        description:
            "Get in touch to discuss your next project. We're here to help turn your ideas into reality with AI and web solutions.",
        type: "website",
    },
    "/blog": {
        title: "Blog | AI Development & Tech Insights",
        description:
            "Insights and articles about AI development, web technologies, and technical consulting from industry experts.",
        type: "blog",
    },
    "/privacy": {
        title: "Privacy Policy | Polity Interfaces",
        description:
            "Our commitment to protecting your privacy and data security at Polity Interfaces.",
        type: "website",
    },
    "/projects/cfb-rankings": {
        title: "CFB Rankings | Polity Interfaces",
        description:
            "CFB Rankings powered by Polity Interfaces.",
        type: "website",
    },
};

export default function SEO() {
    const location = useLocation();
    const currentPath = location.pathname.endsWith("/")
        ? location.pathname.slice(0, -1)
        : location.pathname;
    const meta = { ...defaultMeta, ...(pageMeta[currentPath] || {}) };

    useEffect(() => {
        // Basic meta tags
        document.title = meta.title;
        document
            .querySelector('meta[name="description"]')
            ?.setAttribute("content", meta.description);

        // Open Graph tags
        document
            .querySelector('meta[property="og:title"]')
            ?.setAttribute("content", meta.title);
        document
            .querySelector('meta[property="og:description"]')
            ?.setAttribute("content", meta.description);
        document
            .querySelector('meta[property="og:image"]')
            ?.setAttribute("content", meta.image);
        document
            .querySelector('meta[property="og:image:width"]')
            ?.setAttribute("content", meta.imageWidth);
        document
            .querySelector('meta[property="og:image:height"]')
            ?.setAttribute("content", meta.imageHeight);
        document
            .querySelector('meta[property="og:type"]')
            ?.setAttribute("content", meta.type);
        document
            .querySelector('meta[property="og:url"]')
            ?.setAttribute("content", `https://polityinterfaces.com${currentPath}`);

        // Twitter tags
        document
            .querySelector('meta[property="twitter:title"]')
            ?.setAttribute("content", meta.title);
        document
            .querySelector('meta[property="twitter:description"]')
            ?.setAttribute("content", meta.description);
        document
            .querySelector('meta[property="twitter:image"]')
            ?.setAttribute("content", meta.image);
        document
            .querySelector('meta[name="twitter:card"]')
            ?.setAttribute("content", "summary_large_image");

        // Canonical URL
        const canonicalUrl = `https://polityinterfaces.com${currentPath}`;
        let canonicalTag = document.querySelector('link[rel="canonical"]');
        if (!canonicalTag) {
            canonicalTag = document.createElement("link");
            canonicalTag.setAttribute("rel", "canonical");
            document.head.appendChild(canonicalTag);
        }
        canonicalTag.setAttribute("href", canonicalUrl);

        // Schema.org JSON-LD
        let scriptTag = document.querySelector("#schema-org");
        if (!scriptTag) {
            scriptTag = document.createElement("script");
            scriptTag.id = "schema-org";
            scriptTag.type = "application/ld+json";
            document.head.appendChild(scriptTag);
        }

        const schemaData = {
            "@context": "https://schema.org",
            "@type": meta.type === "profile" ? "Person" : "WebPage",
            "@id": canonicalUrl,
            url: canonicalUrl,
            name: meta.title,
            description: meta.description,
            image: meta.image,
            ...(meta.type === "profile" && {
                givenName: "Trey",
                familyName: "Reynolds",
                alumniOf: "SpaceX",
                jobTitle: "Founder & Lead Engineer",
                worksFor: {
                    "@type": "Organization",
                    name: "Polity Interfaces",
                },
            }),
        };

        scriptTag.textContent = JSON.stringify(schemaData);
    }, [currentPath, meta]);

    return null;
} 