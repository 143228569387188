import { useState, useEffect } from 'react';
import SlimeMoldSimulation from '../../components/SlimeMoldSimulation';

export default function SlimeMold() {
    const [isRunning, setIsRunning] = useState(false);
    const [agentCount, setAgentCount] = useState(10000);
    const [isFullWidth, setIsFullWidth] = useState(() => {
        // Initialize from localStorage or default to false
        const saved = localStorage.getItem('slimeMoldFullWidth');
        return saved ? JSON.parse(saved) : false;
    });

    // Save fullWidth state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('slimeMoldFullWidth', JSON.stringify(isFullWidth));
    }, [isFullWidth]);

    return (
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
            <div className="space-y-12">
                {/* Hero Section */}
                <div className="text-center">
                    <h1 className="text-4xl font-bold">Slime Mold Simulation</h1>
                    <p className="mt-4 text-xl text-gray-600">
                        Exploring emergent behavior through digital life
                    </p>
                </div>

                {/* Simulation Section */}
                <div className={isFullWidth ? 'space-y-8' : 'grid grid-cols-1 gap-8 lg:grid-cols-3'}>
                    {/* Simulation Preview */}
                    <div className={isFullWidth ? 'w-full' : 'lg:col-span-1'}>
                        <div className={`${isFullWidth ? '' : 'sticky top-8'} space-y-4`}>
                            <div className="overflow-hidden rounded-lg bg-black">
                                <SlimeMoldSimulation
                                    width={isFullWidth ? 1200 : 400}
                                    height={isFullWidth ? 800 : 400}
                                    agentCount={agentCount}
                                    isRunning={isRunning}
                                />
                            </div>
                            {/* Controls */}
                            <div className="space-y-4">
                                <div className="flex justify-center space-x-4">
                                    <button
                                        onClick={() => setIsRunning(!isRunning)}
                                        className="inline-flex items-center justify-center rounded-md bg-pi-black px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-pi-black/90"
                                    >
                                        {isRunning ? 'Pause Simulation' : 'Start Simulation'}
                                    </button>
                                    <button
                                        onClick={() => setIsFullWidth(!isFullWidth)}
                                        className="inline-flex items-center justify-center rounded-md border border-pi-black px-6 py-3 text-base font-medium text-pi-black hover:bg-pi-black/5"
                                    >
                                        {isFullWidth ? 'Reduce Size' : 'Enlarge'}
                                    </button>
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Agent Count: {agentCount.toLocaleString()}
                                    </label>
                                    <input
                                        type="range"
                                        min="1000"
                                        max="100000"
                                        step="1000"
                                        value={agentCount}
                                        onChange={(e) => setAgentCount(Number(e.target.value))}
                                        className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content */}
                    <div className={isFullWidth ? 'max-w-3xl mx-auto' : 'lg:col-span-2'}>
                        <div className="prose prose-lg max-w-none">
                            <h2>About the Project</h2>
                            <p>
                                This simulation models the fascinating behavior of Physarum polycephalum, a single-celled organism
                                that exhibits remarkable problem-solving abilities. Despite lacking a brain, slime molds can find
                                optimal paths to food sources and create efficient networks.
                            </p>

                            <h2>How It Works</h2>
                            <p>
                                The simulation uses a particle-based approach where thousands of individual agents follow simple rules:
                            </p>
                            <ul>
                                <li>Deposit pheromone trails while moving</li>
                                <li>Sense and follow gradient of pheromone concentrations</li>
                                <li>Random walk when no pheromone is detected</li>
                                <li>Avoid areas with too many other agents</li>
                            </ul>

                            <h2>Technical Implementation</h2>
                            <p>
                                Built using WebGL for high-performance particle rendering, the simulation can handle up to 100,000
                                agents simultaneously. The computation is optimized using spatial partitioning and runs entirely
                                on the GPU.
                            </p>

                            <h2>Applications</h2>
                            <ul>
                                <li>Network design and optimization</li>
                                <li>Path finding algorithms</li>
                                <li>Urban planning and transportation</li>
                                <li>Research in emergent behavior</li>
                            </ul>

                            <h2>Future Development</h2>
                            <p>
                                Upcoming features include:
                            </p>
                            <ul>
                                <li>Interactive obstacles and food sources</li>
                                <li>Multiple species interaction</li>
                                <li>Environmental factors (temperature, humidity)</li>
                                <li>Data export for analysis</li>
                            </ul>

                            {/* GitHub Link */}
                            <div className="mt-8">
                                <a
                                    href="https://github.com/yourusername/slime-mold-sim"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-flex items-center justify-center rounded-md border border-pi-black px-6 py-3 text-base font-medium text-pi-black hover:bg-pi-black/5"
                                >
                                    View on GitHub
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 