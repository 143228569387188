export default function SuperBallBreaker() {
    return (
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
            <div className="space-y-12">
                {/* Hero Section */}
                <div className="text-center">
                    <h1 className="text-4xl font-bold">Super Ball Breaker</h1>
                    <p className="mt-4 text-xl text-gray-600">
                        An AI-generated arcade game that combines physics, strategy, and pure fun
                    </p>
                </div>

                {/* Two Column Layout */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    {/* Game Preview - Takes up 1/3 */}
                    <div className="lg:col-span-1">
                        <div className="sticky top-8 space-y-4">
                            <div className="overflow-hidden rounded-lg">
                                <img
                                    src="/images/super-ball-breaker.png"
                                    alt="Super Ball Breaker gameplay"
                                    className="w-full"
                                />
                            </div>
                            {/* Call to Action */}
                            <div className="text-center">
                                <a
                                    href="https://apps.apple.com/us/app/super-ball-breaker/id123456789"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-flex items-center justify-center rounded-md bg-pi-black px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-pi-black/90"
                                >
                                    Download on the App Store
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Content - Takes up 2/3 */}
                    <div className="lg:col-span-2">
                        <div className="prose prose-lg max-w-none">
                            <h2>About the Game</h2>
                            <p>
                                Super Ball Breaker is a unique arcade game that puts a fresh spin on the classic brick-breaker genre.
                                Using advanced AI techniques, the game generates increasingly challenging levels that adapt to your
                                play style, ensuring each session is unique and engaging.
                            </p>

                            <h2>Features</h2>
                            <ul>
                                <li>Procedurally generated levels for endless gameplay</li>
                                <li>Dynamic difficulty adjustment based on player performance</li>
                                <li>Physics-based ball mechanics for strategic gameplay</li>
                                <li>Power-ups and special abilities to discover</li>
                                <li>Global leaderboards and achievements</li>
                            </ul>

                            <h2>Development Story</h2>
                            <p>
                                The game was developed using a combination of Unity for the core gameplay mechanics and custom AI
                                algorithms for level generation. The AI was trained on thousands of classic brick-breaker levels
                                to understand what makes a level both challenging and fun.
                            </p>

                            <h2>Technical Details</h2>
                            <ul>
                                <li>Built with Unity Game Engine</li>
                                <li>Custom AI level generation system</li>
                                <li>Advanced physics simulation</li>
                                <li>Cloud-based leaderboard system</li>
                                <li>Optimized for iOS devices</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 